import { useState, useEffect, FC, KeyboardEvent, MouseEvent } from "react";
import { useLocation } from "react-router-dom";
import SimpleBarReact from "simplebar-react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { ListItem, Drawer } from "@mui/material";
import routesPaths from "routes/routesPaths";
import { useMediaQuery } from "helpers/hooks";
import { SquereButton } from "components/Buttons";
import Navigation from "./components/Navigation";
import UserMenu from "./components/UserMenu";
import { AppText, Language } from "components";
import authRoutes from "./components/authNav";
import {
  HeaderWrapper,
  Content,
  LogoLink,
  Logo,
  SeparateContent,
  LogoSeparateContent,
  HeaderContentContainer,
  SideBarLink,
  SideBarLinkContent,
  StyledNavList,
  SidebarButtons,
  StyledBox,
  AdminLoginContainer,
} from "./styled";
import { colors } from "helpers/consts";
import { UserStore } from "stores";

import logoImg from "assets/soc_logo.svg";
// import { ReactComponent as CartIcon } from "assets/icons/cart.svg";
import { ReactComponent as MenuIcon } from "assets/icons/menu.svg";
import { ReactComponent as ArrowIcon } from "assets/icons/chevron-right.svg";

const Header: FC = () => {
  const location = useLocation();
  // const navigate = useNavigate();
  const { t } = useTranslation();

  const { isAuthenticated, isLoggedAsAdmin, setIsLoggedAsAdmin } = UserStore;

  // const mobile = useMediaQuery("(min-width: 38em)");
  const desktop = useMediaQuery("(min-width: 48em)");

  const [isNavOpen, changeIsNavOpen] = useState(false);

  const toggleDrawer =
    (open: boolean) => (event: KeyboardEvent | MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as KeyboardEvent).key === "Tab" ||
          (event as KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      changeIsNavOpen(open);
    };

  useEffect(() => {
    isNavOpen && desktop && changeIsNavOpen(false);
  }, [desktop, isNavOpen]);

  useEffect(() => {
    if (isAuthenticated && !isLoggedAsAdmin) {
      const isAdminLogged = localStorage.getItem("isLoggedAsAdmin");
      if (isAdminLogged) {
        setIsLoggedAsAdmin();
      }
    }
  }, [isAuthenticated, isLoggedAsAdmin, setIsLoggedAsAdmin]);

  useEffect(() => {
    if (isNavOpen) {
      changeIsNavOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <HeaderWrapper>
      <HeaderContentContainer>
        {isLoggedAsAdmin && (
          <AdminLoginContainer>
            <AppText fontWeight={700} fontSize={18}>
              {t("LOGGED_FROM_ADMIN_PANEL")}
            </AppText>
          </AdminLoginContainer>
        )}

        <Content>
          <LogoSeparateContent>
            {isAuthenticated && (
              <LogoLink to={routesPaths.dashboard}>
                <Logo src={logoImg} />
              </LogoLink>
            )}

            {desktop && <Navigation />}
          </LogoSeparateContent>

          <SeparateContent>
            {/* {isAuthenticated && mobile && (
              <MainButton
                onClick={() => navigate(routesPaths.buyCrypto)}
                maxHeight="4rem"
              >
                <CartIcon />
                {t("BUY_CRYPTO")}
              </MainButton>
            )} */}

            <UserMenu />
            <Language />
            {isAuthenticated && !desktop && (
              <SquereButton onClick={() => changeIsNavOpen(true)}>
                <MenuIcon />
              </SquereButton>
            )}
          </SeparateContent>
        </Content>
      </HeaderContentContainer>

      {isAuthenticated && (
        <Drawer anchor="left" open={isNavOpen} onClose={toggleDrawer(false)}>
          <SimpleBarReact
            style={{ width: "31rem", height: "100vh", overflowX: "hidden" }}
            tabIndex={-1}
            forceVisible="y"
          >
            <LogoLink to={routesPaths.dashboard} $isSidebar>
              <Logo src={logoImg} />
            </LogoLink>
            <StyledBox
              sx={{
                width: 310,
              }}
              role="presentation"
              onKeyDown={toggleDrawer(false)}
            >
              <StyledNavList>
                {authRoutes.map(({ path, key, label, icon }) => {
                  return (
                    <ListItem key={key} disablePadding>
                      <SideBarLink to={path} onClick={toggleDrawer(false)}>
                        <SideBarLinkContent>
                          {icon}{" "}
                          <AppText fontWeight={600} color={colors.gray_600}>
                            {t(label)}
                          </AppText>
                        </SideBarLinkContent>
                        <ArrowIcon />
                      </SideBarLink>
                    </ListItem>
                  );
                })}
              </StyledNavList>

              <SidebarButtons>
                <UserMenu isInSidebar />
                <Language />
                {/* {!mobile && (
                  <MainButton
                    onClick={() => navigate(routesPaths.buyCrypto)}
                    fullWidth
                  >
                    <CartIcon />
                    {t("BUY_CRYPTO")}
                  </MainButton>
                )} */}
              </SidebarButtons>
            </StyledBox>
          </SimpleBarReact>
        </Drawer>
      )}
    </HeaderWrapper>
  );
};

export default observer(Header);
